.main-card {
  width: 70%;
  border-radius: 30px;
  overflow: hidden;
  height: 650px;
}

.quiz {
  font-family: NeologyDecoW03-Medium;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 55px
}

.btn {
  border-radius: 20px !important;
  width: 206px;
  height: 50px;
}

.learnn1 {
  display: none;
}

.card-video {
  border-radius: 60px;
  border: 1px solid #EDEDED;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.middle-page {
  height: fit-content;
}

@media screen and (max-width: 1000px) {
  .main-card {
    width: 100%;
    margin-top: 0 !important;
  }
}

.row {
  width: 100% !important;
}

@media screen and (max-width: 700px) {
  .main-card {
    flex-direction: column !important;
    height: fit-content !important;
    border-radius: 0px !important;
  }

  .card-body{
    align-items: center;
    display: flex
;
    flex-direction: column;
  }

  .scrollable-section {
    justify-content: center;
    display: flex;
  }

  .for-center {
    align-items: center !important;
    justify-content: center !important;
    display: flex;
  }

  .ul-list {
    flex-direction: row !important;
    height: 0 !important;
    justify-content: space-between !important;
    padding: 25px !important;
  }

  .cover-page {
    flex-direction: column-reverse;

  }

  .start-pic {
    height: 300px !important;
  }

  .middle-page {
    width: 100% !important;
    margin: 0 !important;
    margin-top: 5px !important;
  }


  .card-x {
    max-width: 100% !important;
  }

  .scroll-bar {
    height: fit-content !important;
    justify-content: center;
  }

  .scroll-bar {
    height: fit-content !important;
    justify-content: center;
  }


  .main-card {
    width: 100%;
    border-radius: 0px !important;
    height: 100vh;
  }


  .scroll-bar {
    overflow: scroll !important;
    margin-top: 0px;
  }

  .full-display {
    height: 100vh;
  }

  .learnn {
    flex-direction: row !important;
  }

  .learnn1 {
    display: block !important;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 5px;
  }

  .earnbottom {
    margin-bottom: 0 !important;
  }
}

.scroll-bar {
  overflow: scroll;
  height: 620px;

}

.scroll-bar::-webkit-scrollbar {
  display: none;
}


body {
  font-family: 'Arial', sans-serif;
  position: relative;
}

.sidebar {
  height: 100vh;
  background-color: #fff;
  padding: 20px;
  border-right: 1px solid #dee2e6;
}

.sidebar .nav-link {
  color: #495057;
  font-size: 16px;
  margin-bottom: 10px;
}

/* .sidebar .nav-link.active {
  color: #007bff;
} */

.sidebar .nav-link i {
  margin-right: 10px;
}

.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-header {
  background-color: #fff;
  border-bottom: none;
  font-weight: bold;
}

.project-card img {
  width: 100% !important;
  height: 200px;
  max-width: 300px;
}

.for-pad {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-card {
  margin-top: 15px;
  padding: 15px;
}

.card-body {
  padding: 20px;
}

.overview-card {
  text-align: center;
}

.overview-card h5 {
  font-size: 14px;
  color: #6c757d;
}

.overview-card h3 {
  font-size: 24px;
  margin: 10px 0;
}

.overview-card .progress {
  height: 5px;
  margin-top: 10px;
}

.chart-container {
  height: 300px;
}

.content {
  flex-grow: 1;
  background-color: #fff;
}

.buttons-excel {
  width: 5px !important;
  height: 25px !important;
  background: url("https://miro.medium.com/v2/resize:fit:828/format:webp/1*XFXNpitBO_ZJlTp30wBgnQ.png") !important;
  background-size: cover !important;
}

Modal Overlay .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  position: relative;
}

/* Modal Header */
.modal-header1 {
  margin-bottom: 20px;
  height: 80px;
}

.modal-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  position: absolute;
}

.modal-body {
  margin-bottom: 10px;
}

/* Modal Footer */
.modal-footer-quiz {
  margin-top: 10px;
  justify-content: center !important;
}

/* Submit Quiz - Clickable Div (styled as button) */
.submit-quiz {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.submit-quiz:hover {
  background-color: #0056b3;
}

/* Close Modal - Clickable Div */
.close-modal {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.close-modal:hover {
  background-color: #d32f2f;
}

.row-left {
  justify-content: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.row-left label {
  font-weight: 500;
}


.progress-bar {
  background-color: green !important;
}

.social-icons {
  display: flex;
  gap: 15px;
  justify-content: right;
  align-items: right;
  padding: 10px;
}

.social-icons .icon {
  width: 30px;
  height: 20px;
  fill: #333;
  /* Default icon color */
  transition: fill 0.3s;
}

.social-icons .icon:hover {
  fill: #0077b5;
  /* Change color on hover */
}

.custom-btn {
  padding: 10px 20px;
  width: 100% !important;
  font-size: 16px;
  font-weight: bold;
  background-color: #022196 !important;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.custom-btn:hover {
  background-color: #0b2b4e;
}

/* ======loader css========== */
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 100%;
  z-index: 9999;
  backdrop-filter: blur(2px);

}

.video-list::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.video-list::-webkit-scrollbar-thumb {
  background-color: #66d7ff ; /* Set the thumb color to #00bfff */
  border-radius: 10px;
}

.video-list::-webkit-scrollbar-thumb:hover {
  background-color: #00bfff; /* Lighter color on hover (light cyan) */
}

.video-list::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

/* Hover effect for links */
.nav-link:hover {
  background-color: #f8f9fa;
  color: #007bff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Active state for the link */
.nav-link.active {
  background-color: #007bff;
  color: white;
}

/* Optional: For a smoother effect on hover */
.nav-link {
  transition: background-color 0.3s ease, color 0.3s ease;
}


/* ================admin sidebar================= */
.nav-link {
  transition: all 0.3s ease-in-out;
  padding: 12px 25px;
  border-radius: 10px; /* Smoother corners for a modern look */
  font-weight: 600; /* Slightly bolder text */
  letter-spacing: 0.5px;
  color: #6c757d; /* Neutral default text color */
  background: #f8f9fa; /* Subtle background for inactive state */
  text-decoration: none; /* Remove underline */
  display: flex;
  align-items: center; /* Align icon and text vertically */
  gap: 8px; /* Space between icon and text */
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0); /* No shadow initially */
  cursor: pointer; /* Pointer cursor for interactivity */
}

/* Hover Effect */
.nav-link:hover {
  background: linear-gradient(135deg, #17a2b8, #7fcdff); /* Gradient background */
  transform: translateY(-4px) scale(1.08); /* Slight lift and scaling */
  box-shadow: 0 10px 30px rgba(23, 162, 184, 0.4), 0 6px 10px rgba(0, 0, 0, 0.1);
  text-shadow: 2px 2px 8px rgba(23, 162, 184, 0.7); /* Glow effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease; /* Smooth transition for all hover effects */
}

/* Icon and Text Hover Effect */
.nav-link:hover i,
.nav-link:hover span {
  transform: rotate(5deg) scale(1.1); /* Slight rotation and zoom */
  color: #fff;
}

/* Active State */
.nav-link.active {
  background: linear-gradient(135deg, #17a2b8, #7fcdff); /* Same gradient */
  transform: translateY(-4px) scale(1.08); /* Maintain hover effect */
  box-shadow: 0 12px 35px rgba(23, 162, 184, 0.5), 0 8px 15px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow */
  text-shadow: 2px 2px 10px rgba(23, 162, 184, 0.8); /* Stronger glow effect */
}

/* Icon Styles */
.nav-link i {
  font-size: 1.2rem; /* Larger, balanced icon size */
  transition: transform 0.3s ease; /* Smooth transition for icon */
}

/* Accessibility Focus */
.nav-link:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(23, 162, 184, 0.5); /* Focus ring for better accessibility */
}



.sidebar {
  height: 100vh;
  width: 250px;
  transform: translateX(-100%);
  position: fixed;
  z-index: 999;
}

.sidebar.open {
  transform: translateX(0);
}

@media (min-width: 768px) {
  .sidebar {
    transform: translateX(0);
    position: static;
    width: 20%;
  }
}


.port-view {
  display: flex;
  height: 100vh;
  justify-content: center;
  justify-items: center;
  align-items: center;
  position: relative;
}

.wrapper {
  width: 390px;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 15px 20px rgba(0, 0, 0, .1);
  overflow: hidden;
}

.wrapper .title-text {
  display: flex;
  width: 200%;
}

.wrapper .title-text .title {
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #555;
}

.wrapper .form-container {
  width: 100%;
  overflow: hidden;
}

.form-container .slide-controls {
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  border: 1px solid lightgrey;
  overflow: hidden;
  margin: 0px 0 0px 0;
  border-radius: 10px;
  position: relative;
}

.slide-controls .slide {
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  z-index: 1;
  transition: all .6s ease;
}

.slide-controls .signup {
  color: #212121;
}

.slide-controls .slide-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
  z-index: 0;
  background: -webkit-linear-gradient(left, #0c9cda, #03609e);
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

#signup:checked~.slide-tab {
  left: 50%;
}

#signup:checked~.signup {
  color: #fff;
}

#signup:checked~.login {
  color: #212121;
}

.form-container .form-inner {
  display: flex;
  width: 200%;
}

.form-container .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  ;
}

.form-inner form .field {
  height: 50px;
  width: 100%;
  margin-top: 20px;
}

.form-inner form .field input {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 17px;
  padding-left: 15px;
  border-radius: 10px;
  border: 1px solid lightgray;
  border-bottom-width: 2px;
  transition: all 0.4s ease;
}

.form-inner form .field input:focus {
  border-color: #0c9cda;
}

.form-inner form .pass-link {
  margin-top: 20px;
}

.form-inner form .pass-link a,
a {
  color: #0c9cda;
  text-decoration: none;
}

.form-inner form .signup-link {
  color: #212121;
  text-align: center;
  margin-top: 30px;
}

.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
  text-decoration: underline;
}

form .field input[type="submit"] {
  background: -webkit-linear-gradient(left, #0c9cda, #046AA8);
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding-left: 0;
  border: none;
  cursor: pointer;
}

.middle-page {
  width: 8%;
  margin-left: 10px;
}


/* ======== */
.form-container {
  width: 400px;
  /* Set your desired width */
  height: 500px;
  /* Set your desired height */
  margin: auto;
  /* Center the container */
  padding: 20px;
  /* Add some padding */
}

.form-inner {
  position: relative;
  width: 100%;
  overflow-y: auto;
  /* Enable vertical scrolling */
  max-height: 500px;
  /* Set a maximum height */
}

.signup {
  overflow-y: auto;
  max-height: 500px;
  /* Adjust the height according to your needs */
}

.mobile-flex {
  flex-direction: row !important;
}

.image-size {
  width: 150px;
  height: auto;
  object-fit: cover;
}


.dropdown-item-custom {
  font-weight: 500;
  color: #333;
}

.dropdown-item-custom:hover {
  background-color: #2196f3;
  color: white;
  font-weight: bold;
}

.dropdown-item-custom:active {
  background-color: #21d1f7;
  color: white;
  font-weight: bold;
}

html,
body {
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(15, 174, 230, 1) 100%);
}

.swal-confirm-red {
  background-color: #e74c3c !important;  /* Red color */
  color: white;
}

.swal-confirm-red:hover {
  background-color: #c0392b !important;  /* Darker red for hover */
}